import { useSyncExternalStore } from 'react';

// maintain stable references
const subscribe = () => () => {};
const isClient = () => true;
const isServer = () => false;

export const useCheckIfMounted = () => {
  // useSyncExternalStore is the canonical React method to check if the code is running in client or server context.
  // Meaning: no hydration warnings.
  return useSyncExternalStore(subscribe, isClient, isServer);
};
