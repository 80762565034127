import { v4 } from 'uuid';

export const generateRandomId = () => v4();

export const exists = <T>(x?: T | null): x is T => !!x;

export const assertExists = <T>(x?: T | null): T => {
  if (!exists(x)) {
    throw new Error('Expected value does not exist');
  }
  return x;
};

/**
 * Accumulator function for reducing array of functions with.
 * Will return the first return value of input functions that is not zero.
 */
export const accumulateUntilNonZero = (acc: number, curr: () => number) => {
  if (acc !== 0) {
    return acc;
  }
  return curr();
};

export const noop = () => {};

export const isEqual = (a: any, b: any): boolean => {
  if (a === b) {
    return true;
  }

  if (Array.isArray(a)) {
    if (!Array.isArray(b) || a.length !== b.length) {
      return false;
    }

    return a.every((item, index) => isEqual(item, b[index]));
  }

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();

    if (!isEqual(aKeys, bKeys)) {
      return false;
    }

    return aKeys.every((key) => isEqual(a[key], b[key]));
  }

  return false;
};
