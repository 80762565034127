export default function contentfulLoader({ src, width, quality }) {
  if (src.endsWith('.svg')) {
    return src;
  }

  const url = new URL(`https:${src}`);
  url.searchParams.set('fm', 'webp');
  url.searchParams.set('w', width.toString());
  url.searchParams.set('q', (quality || 65).toString());
  return url.href;
}
