import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/font/local/target.css?{\"path\":\"src/components/structural/common/Layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../fonts/SuisseIntl-Regular-WebS.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../fonts/SuisseIntl-Medium-WebS.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../fonts/SuisseIntl-SemiBold-WebS.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"display\":\"block\"}],\"variableName\":\"suisse\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/next/font/local/target.css?{\"path\":\"src/components/structural/common/Layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../fonts/SuisseIntlMono-Bold-WebS.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"block\"}],\"variableName\":\"suisseMono\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/global.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/node_modules/@vrfi/web-components/css/breakpointSlices.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/common/AppClient.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/CommonRootComponents/CommonRootComponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReCaptchaScript"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/ReCaptcha/ReCaptchaScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteDataClientProvider"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/SiteDataProvider/SiteDataClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/vr-web/vr-web/packages/front/src/components/structural/TranslationsProvider/ClientIntlProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/vr-web/vr-web/packages/front/src/styles/global.css");
