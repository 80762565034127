import { LOCALE } from './i18n';

// https://date-fns.org/v2.25.0/docs/format

export const ISO_LONG_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxxxx";
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_AND_WEEKDAY_FORMAT = 'd. cccc';
export const MONTH_AND_YEAR_FORMAT = 'LLLL y';
export const DATE_FORMAT = 'd.M.y';
export const NEWS_ARTICLE_SLUG_DATE_FORMAT = 'yyyy/MM/dd';

export const CRM_DATE_FORMAT = "yyyy-MM-dd'T00:00:00Z'";

export const DATEPICKER_BUTTON_ARIA_FORMAT = 'cccc d MMMM y';

export const DAY_AND_MONTH_FORMAT: Record<LOCALE, string> = {
  [LOCALE.Fi]: 'd.M.',
  [LOCALE.En]: 'd.M.',
  [LOCALE.Sv]: 'd.M',
};

export const WEEKDAY_AND_DATE_FORMAT_SHORT: Record<LOCALE, string> = {
  [LOCALE.Fi]: 'EEEEEE d.M.',
  [LOCALE.En]: 'EEE d.M.',
  [LOCALE.Sv]: 'EEEEEE d.M',
};

export const WEEKDAY_AND_DATE_FORMAT: Record<LOCALE, string> = {
  fi: 'cccc d.M.',
  en: 'cccc d.M.',
  sv: 'cccc d.M',
};

export const WEEKDAY_AND_DATE_FORMAT_LONG: Record<LOCALE, string> = {
  fi: 'cccc d.M.y',
  en: 'cccc d.M.y',
  sv: 'cccc d.M.y',
};

export const SHORT_WEEKDAY_FORMAT: Record<LOCALE, string> = {
  fi: 'EEEEEE',
  en: 'EEE',
  sv: 'EEEEEE',
};
export const SHORT_WEEKDAY_AND_DATE_FORMAT: Record<LOCALE, string> = {
  fi: 'EEEEEE d.M.yyyy',
  en: 'EEE d.M.yyyy',
  sv: 'EEEEEE d.M.yyyy',
};

export const TIME_FORMAT = 'H:mm';
export const FIXED_TIME_FORMAT = 'HH:mm';
