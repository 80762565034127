import Cookies from 'js-cookie';

import { isSSR } from './isSSR';

const cookieNames = {
  accessTokensValid: 'access_tokens_valid',
  identityToken: 'identityToken',
  selectedRole: 'selectedRole',
  accessToken: 'accessToken',
  idToken: 'idToken',
  loggedIn: 'loggedIn',
};

/** @private export used for testing */
export const isAccessTokenValid = () => {
  if (isSSR()) {
    return false;
  }
  return Boolean(Cookies.get(cookieNames.accessTokensValid));
};

export const getLoggedInCookie = (): boolean =>
  !isSSR() && Cookies.get(cookieNames.loggedIn) === 'true';

export const CUSTOMER_ROLE_IDENTIFIER = 'customer';
export const getSelectedRole = (): string | undefined =>
  isSSR() ? undefined : Cookies.get(cookieNames.selectedRole);
export const setSelectedRole = (role?: string) =>
  role ? Cookies.set(cookieNames.selectedRole, role) : removeSelectedRole();
export const removeSelectedRole = () => Cookies.remove(cookieNames.selectedRole);

export const cleanupAccessCookies = () => {
  Cookies.remove(cookieNames.accessToken);
  Cookies.remove(cookieNames.idToken);
  Cookies.remove(cookieNames.identityToken);
  Cookies.remove(cookieNames.accessTokensValid);
};
