import { GroupSalesJourneyOptionFragment } from 'backend/types.codegen';

import { PlaceWishes } from 'components/ui/Forms/GroupSales';

import { UserIdentity } from 'contexts/identity';

import { FeatureFlag } from 'utils/featureFlags';

export type CookieConsentLevels = {
  level1: boolean;
  level2: boolean;
  level3: boolean;
  level4: boolean;
};

type CookieConsent = {
  consented: boolean;
  levels: CookieConsentLevels;
};

/** @private export used for testing */
export type GroupSalesJourneyOption = GroupSalesJourneyOptionFragment & {
  placeWishes: PlaceWishes[];
};

export type GroupSalesJourneyState = {
  outboundOption: GroupSalesJourneyOption | null;
  returnOption: GroupSalesJourneyOption | null;
};

export enum StorageKey {
  Preview = 'preview',
  CookieConsent = 'cookieConsent',
  LocalizationTooltips = 'localizationTooltips',
  LastJourneyDate = 'lastJourneyDate',
  RecentStations = 'recentStations',
  RecentRoutes = 'recentRoutes',
  ReadNotifications = 'readNotifications',
  SessionId = 'sessionId',
  DismissSalesSessionExpiration = 'dismissSalesSessionExpiration',
  FeatureFlags = 'featureFlags',
  GroupSalesJourney = 'groupSalesJourney',
  PreviouslySelectedRole = 'previouslySelectedRole',
  LastKnownRequestId = 'lastKnownRequestId',
  UserIdentity = 'userIdentity',
  SelectedBusDestination = 'selectedBusDestination',
  FinalizedSales = 'finalizedSales',
  PreviouslyLoggedIn = 'previouslyLoggedIn',
  HasDismissedProfileTooltip = 'hasDismissedProfileTooltip',
  AllowConvertTests = 'allowConvertTests',
  LoginTooltipShown = 'loginTooltipShown',
  DiscardedMultiTicketAd = 'discardedMultiTicketAd',
}

export interface StorageData {
  [StorageKey.Preview]: boolean;
  [StorageKey.CookieConsent]: CookieConsent;
  [StorageKey.LocalizationTooltips]: boolean;
  [StorageKey.LastJourneyDate]: string | null;
  [StorageKey.RecentStations]: string[] | null;
  [StorageKey.RecentRoutes]: string[] | null;
  [StorageKey.ReadNotifications]: string[] | null;
  [StorageKey.SessionId]: string | null;
  [StorageKey.DismissSalesSessionExpiration]: string | null;
  [StorageKey.FeatureFlags]: Partial<Record<FeatureFlag, boolean>> | null;
  [StorageKey.GroupSalesJourney]: GroupSalesJourneyState;
  [StorageKey.PreviouslySelectedRole]: string | null;
  [StorageKey.LastKnownRequestId]: string | null;
  [StorageKey.UserIdentity]: UserIdentity | null;
  [StorageKey.SelectedBusDestination]: string | null;
  [StorageKey.FinalizedSales]: Array<{ saleId: string; sessionId: string | null }>;
  [StorageKey.PreviouslyLoggedIn]: boolean;
  [StorageKey.HasDismissedProfileTooltip]: boolean;
  [StorageKey.AllowConvertTests]: boolean;
  [StorageKey.LoginTooltipShown]: number | null;
  [StorageKey.DiscardedMultiTicketAd]: string | null;
}

export const storeMap: Record<StorageKey, 'session' | 'local'> = {
  [StorageKey.Preview]: 'session',
  [StorageKey.CookieConsent]: 'local',
  [StorageKey.LocalizationTooltips]: 'session',
  [StorageKey.LastJourneyDate]: 'local',
  [StorageKey.RecentStations]: 'local',
  [StorageKey.RecentRoutes]: 'local',
  [StorageKey.ReadNotifications]: 'local',
  [StorageKey.SessionId]: 'session',
  [StorageKey.DismissSalesSessionExpiration]: 'local',
  [StorageKey.FeatureFlags]: 'local',
  [StorageKey.GroupSalesJourney]: 'session',
  [StorageKey.PreviouslySelectedRole]: 'local',
  [StorageKey.LastKnownRequestId]: 'session',
  [StorageKey.UserIdentity]: 'local',
  [StorageKey.SelectedBusDestination]: 'session',
  [StorageKey.FinalizedSales]: 'local',
  [StorageKey.PreviouslyLoggedIn]: 'local',
  [StorageKey.HasDismissedProfileTooltip]: 'local',
  [StorageKey.AllowConvertTests]: 'local',
  [StorageKey.LoginTooltipShown]: 'local',
  [StorageKey.DiscardedMultiTicketAd]: 'local',
};

export const initialValues: { [key in StorageKey]: StorageData[key] } = {
  [StorageKey.Preview]: false,
  [StorageKey.LocalizationTooltips]: false,
  [StorageKey.CookieConsent]: {
    consented: false,
    levels: {
      level1: false,
      level2: false,
      level3: false,
      level4: false,
    },
  },
  [StorageKey.LastJourneyDate]: null,
  [StorageKey.RecentStations]: null,
  [StorageKey.RecentRoutes]: null,
  [StorageKey.ReadNotifications]: [],
  [StorageKey.SessionId]: null,
  [StorageKey.DismissSalesSessionExpiration]: null,
  [StorageKey.FeatureFlags]: null,
  [StorageKey.GroupSalesJourney]: {
    outboundOption: null,
    returnOption: null,
  },
  [StorageKey.PreviouslySelectedRole]: null,
  [StorageKey.LastKnownRequestId]: null,
  [StorageKey.UserIdentity]: null,
  [StorageKey.SelectedBusDestination]: null,
  [StorageKey.FinalizedSales]: [],
  [StorageKey.PreviouslyLoggedIn]: false,
  [StorageKey.HasDismissedProfileTooltip]: false,
  [StorageKey.AllowConvertTests]: false,
  [StorageKey.LoginTooltipShown]: null,
  [StorageKey.DiscardedMultiTicketAd]: null,
};
