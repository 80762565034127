/* eslint-disable no-restricted-syntax */
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import differenceInDays from 'date-fns/differenceInDays';
// eslint-disable-next-line no-restricted-imports
import endOfDay from 'date-fns/endOfDay';
// eslint-disable-next-line no-restricted-imports
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';

import { ISO_LONG_DATE_FORMAT } from 'constants/date';

export const isDayBefore = (a: Date, b: Date) => differenceInDays(a, b) < 0;
export const isDayAfter = (a: Date, b: Date) => differenceInDays(a, b) > 0;

type DateFnFormatDateInput = Parameters<typeof formatInTimeZone>[0];
type DateFnFormatDefinition = Parameters<typeof formatInTimeZone>[1];
export type DateFnFormatOptions = Parameters<typeof formatInTimeZone>[3];

export const formatDateUTC: (
  date: DateFnFormatDateInput | string,
  format: DateFnFormatDefinition,
  options?: DateFnFormatOptions,
) => ReturnType<typeof formatInTimeZone> = (date, format, opts) => {
  return formatInTimeZone(createDate(date), 'UTC', format, opts);
};

export const formatDateFinnishTZ: (
  date: DateFnFormatDateInput | string,
  format: DateFnFormatDefinition,
  options?: DateFnFormatOptions,
) => ReturnType<typeof formatInTimeZone> = (date, format, opts) => {
  return formatInTimeZone(createDate(date), 'Europe/Helsinki', format, opts);
};

/**
 * Sanitize date-like input to interpret its input more reliably as UTC.
 *
 * Given following input, returns:
 *
 * - Date -> return as is.
 *
 * - String -> Parse date out using new Date() constructor, with one tweak:
 *             Input in format 2022-01-21T00:00:00 in regular Date() constructor returns local time,
 *             whereas this function will construct an UTC timestamp at that hour.
 *
 * - number -> Treat as milliseconds from UTC epoch. Same as new Date() constructor.
 *
 * - Anything else -> new Date(); -> current timestamp.
 */
export const createDate = (dateLike?: unknown) => {
  if (dateLike instanceof Date) {
    return dateLike;
  }
  if (typeof dateLike === 'string' && !!dateLike) {
    // The normal Date constructor, if given a string containing time parts but no explicit time zone,
    // like 2021-01-01T00:00:00 or 2021-01-01 00:00:00, will construct a Date that matches that time in client local time zone.
    // For consistency, we want to create a Date matching the time in UTC.
    if (
      /^[0-9]{4}-[0-9]{2}-[0-9]{2}(?:T| )[0-9]{2}:[0-9]{2}(:[0-9]{2})?(.[0-9]{1,3})?$/.test(
        dateLike,
      )
    ) {
      return new Date(`${dateLike}Z`);
    }
    const d = new Date(dateLike);
    if (!isNaN(d.valueOf())) {
      return d;
    }
  }
  if (typeof dateLike === 'number') {
    return new Date(dateLike);
  }
  return new Date();
};

/**
 * Generate a date from ISO date (2022-06-10). Time is 0.00 at Finland's timezone.
 */
export const createDateFromISODate = (isoDate: string): Date => {
  if (!isoDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
    throw new Error(`Invalid date: ${isoDate}`);
  }

  const dateInUtc = new Date(`${isoDate}T00:00:00Z`);
  const offset = getTimezoneOffset('Europe/Helsinki', dateInUtc);
  return new Date(dateInUtc.getTime() - offset);
};

/**
 * Note that you can't trust the time zone of a JS Date object. Date doesn't have a time zone, it's a property of the client locale.
 * For formatting Dates including time zone information, please use utilities formatDateUTC() or formatDateFinnishTZ().
 */
export const dateInTimezone =
  (timeZone = 'UTC') =>
  (dateLike?: Date | number | string) => {
    return new Date(createDate(dateLike).toLocaleString('en-US', { timeZone }));
  };
export const dateUTC = dateInTimezone('UTC');
export const dateFinnishTZ = dateInTimezone('Europe/Helsinki');

const dateDivisors = {
  days: 1000 * 60 * 60 * 24,
  hours: 1000 * 60 * 60,
  minutes: 1000 * 60,
  seconds: 1000,
};

/**
 * Calculates duration between two Date objects, in { days: 1, hours: 2, minutes: 3, seconds: 4 } format.
 * It does not matter which Date is given first, this will always return a >= 0 difference.
 */
export const getDurationBetween = (a: Date, b: Date): typeof dateDivisors => {
  const millisecondsBetween = Math.abs(a.valueOf() - b.valueOf());
  return {
    seconds: Math.floor(millisecondsBetween / dateDivisors.seconds) % 60,
    minutes: Math.floor(millisecondsBetween / dateDivisors.minutes) % 60,
    hours: Math.floor(millisecondsBetween / dateDivisors.hours) % 24,
    days: Math.floor(millisecondsBetween / dateDivisors.days),
  };
};

export const isLeapYear = (year: number): boolean =>
  year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);

/**
 * Parses date and time in finnish timezone.
 * @example parseDateAndTimeInFinnishTimezone("2023-06-06", "12:00:00") // 2023-06-06T12:00:00+03:00
 * @param date Date yyyy-mm-dd
 * @param time Time hh:mm:ss:sss
 * @returns ISO date time
 */
export const parseDateAndTimeInFinnishTimezone = (date: string, time: string) => {
  if (!date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
    throw new Error('Date in invalid format: ' + date);
  }

  if (!time.match(/^[0-9]{2}:[0-9]{2}(:[0-9]{2})?(.[0-9]{1,3})?$/)) {
    throw new Error('Time in invalid format: ' + time);
  }

  return formatDateFinnishTZ(
    zonedTimeToUtc(`${date}T${time}`, 'Europe/Helsinki'),
    ISO_LONG_DATE_FORMAT,
  );
};

export const startOfDayInFinland = (date: Date) => {
  const finlandTime = utcToZonedTime(date, 'Europe/Helsinki');
  const endOfDayInFinland = startOfDay(finlandTime);
  return zonedTimeToUtc(endOfDayInFinland, 'Europe/Helsinki');
};

export const endOfDayInFinland = (date: Date) => {
  const finlandTime = utcToZonedTime(date, 'Europe/Helsinki');
  const endOfDayInFinland = endOfDay(finlandTime);
  return zonedTimeToUtc(endOfDayInFinland, 'Europe/Helsinki');
};

/**
 * Deadline is always until midnight
 * @param time DateLike
 * @param daysUntilDeadline integer (7 equals one week)
 */
export const isPastDeadline = (time: string, daysUntilDeadline: number) => {
  const current = createDate();
  const deadline = endOfDayInFinland(subDays(createDate(time), daysUntilDeadline));
  return current > deadline;
};
