import { isSSR } from './isSSR';

const noop = () => {};

const noopStorage: Storage = {
  length: -1,
  clear: noop,
  key: () => null,
  getItem: () => null,
  setItem: noop,
  removeItem: noop,
};

function verifyStorage(storage: Storage) {
  return isStorageEnabled(storage) ? storage : noopStorage;
}

function isStorageEnabled(storage: Storage) {
  if (!storage) {
    return false;
  }

  try {
    const testKey = `${Date.now().toString(36)}_test`;
    const value = Math.random().toString(36);
    storage.setItem(testKey, value);
    if (storage.getItem(testKey) !== value) {
      return false;
    }
    storage.removeItem(testKey);
  } catch (e) {
    return false;
  }
  return true;
}

export const localStorage = verifyStorage(global.localStorage);
export const sessionStorage = verifyStorage(global.sessionStorage);

export const isLocalStorageDisabled = !isSSR() && !isStorageEnabled(global.localStorage);
