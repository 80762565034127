'use client';
import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';
import { useIsMounted } from 'contexts/isMounted';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';

import { initialValues, StorageData, StorageKey, storeMap } from 'types/stateStore';

import { localStorage, sessionStorage } from 'utils/storage';

const reactiveVars = Object.values(StorageKey).reduce(
  (acc, key) => ({
    ...acc,
    [key]: makeVar(null),
  }),
  {} as { [key in StorageKey]: ReactiveVar<StorageData[key] | null> },
);

const getValue = <T>(storage: Storage, key: StorageKey) => {
  const value = storage.getItem(key);
  try {
    return value ? (JSON.parse(value) as T) : null;
  } catch {
    return null;
  }
};

export const useStoredState = <Key extends keyof StorageData>(
  key: Key,
): [StorageData[Key], Dispatch<SetStateAction<StorageData[Key]>>, () => void] => {
  const mounted = useIsMounted();
  const storage = storeMap[key] === 'local' ? localStorage : sessionStorage;
  const reactiveVar = reactiveVars[key];
  const valueFromReactiveVar = useReactiveVar(reactiveVar);

  const value = useMemo((): StorageData[Key] => {
    const valueFromStorage = getValue<StorageData[Key]>(storage, key);

    if (mounted && valueFromReactiveVar !== null) {
      return valueFromReactiveVar;
    } else if (mounted && valueFromStorage !== null) {
      return valueFromStorage;
    }

    return initialValues[key];
  }, [key, mounted, storage, valueFromReactiveVar]);

  const setState: Dispatch<SetStateAction<StorageData[Key]>> = useCallback(
    (setStateAction) => {
      setStoredState(key, setStateAction);
    },
    [key],
  );

  const remove = useCallback(() => clearStoredState(key), [key]);

  useEffect(() => {
    const stored = getValue<StorageData[Key]>(storage, key);
    if (stored !== null) {
      reactiveVar(stored);
    }
  }, [key, reactiveVar, storage]);

  useEffect(() => {
    const onStorage = (event: StorageEvent) => {
      if (event.key !== key) {
        return;
      }
      reactiveVar(getValue<StorageData[Key]>(storage, key));
    };

    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  });

  return [value, setState, remove];
};

/** @private export used for testing */
export const useInitialStoredStateValue = <Key extends keyof StorageData>(key: Key) =>
  useMemo(() => getStoredState(key), [key]);

export const getStoredState = <Key extends StorageKey>(key: Key) => {
  const storage = storeMap[key] === 'local' ? localStorage : sessionStorage;
  const valueFromStorage = getValue<StorageData[Key]>(storage, key);
  return valueFromStorage || initialValues[key];
};

export const setStoredState = <Key extends StorageKey>(
  key: Key,
  value: SetStateAction<StorageData[Key]>,
) => {
  const newValue = typeof value === 'function' ? value(getStoredState(key)) : value;
  const storage = storeMap[key] === 'local' ? localStorage : sessionStorage;
  const reactiveVar = reactiveVars[key];

  if (newValue !== initialValues[key]) {
    storage.setItem(key, JSON.stringify(newValue));
  } else {
    storage.removeItem(key);
  }

  reactiveVar(newValue);
};

export const clearStoredState = (key: StorageKey) => {
  const storage = storeMap[key] === 'local' ? localStorage : sessionStorage;
  const reactiveVar = reactiveVars[key];
  storage.removeItem(key);
  reactiveVar(null);
};

/**
 * This is used between tests to clear state
 * @private export used for testing
 */
export const TEST_clearReactiveVars = () => {
  Object.values(reactiveVars).forEach((reactiveVar) => reactiveVar(null));
};
